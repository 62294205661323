import React from 'react';
import { graphql } from 'gatsby';
import TransitionLink from 'gatsby-plugin-transition-link';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from './../components/layout';
import { getLanguageSlug } from './../utils/helpers';
import linkIcon from './../images/red-arrow-right-bottom.svg';

const NotFoundPage = ({ data }) => {
	const [pageData] = data.locales.edges;
	const parsedData = JSON.parse(pageData.node.data);
	const { t, i18n } = useTranslation();

	return (
		<Layout allPages={parsedData.buildings.pages} minHeight>
			<div className="flex items-center justify-center w-screen h-screen">
				<div className="w-full text-center max-w-screen-3xl px-36">
					<h1 className="font-bold text-20 pb-30">
						{t('notFound404.title')}
					</h1>
					<p className="pb-16 text-16">
						{t('notFound404.description')}
					</p>
					<div className="flex justify-center">
						<TransitionLink
							to={getLanguageSlug(i18n.language)}
							className="font-bold text-12"
						>
							{t('notFound404.goHome')}
						</TransitionLink>
						<img className="ml-10" src={linkIcon} alt="Link" />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default NotFoundPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;